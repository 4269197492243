import React, { useState, useEffect, useRef } from 'react';
import { Link, graphql, Script } from 'gatsby';
import Layout from '../../layout/Layout';
import SEO from '../../components/Seo';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
// import "https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/css/v2/otnotice-core.css"

const Index = ({ data }) => {
  const [content, setContent] = useState("")
  const policyTextRef = useRef()
  const link = "https://privacyportal-cdn.onetrust.com/73dca12b-5ba4-4937-9072-b5ffa15d1ba7/privacy-notices/c4bf13c9-3e34-4792-8ca5-3debf35b55da-fr.json"

  useEffect(() => {
    fetch(link)
      .then((response) => response.json())
      .then((data) => setContent(data.notices[Object.keys(data.notices)[0]]));
  }, [])

  const headerData = data.allContentfulHeader.nodes[0];
  return (
    <Layout>
      <SEO
        lang="fr"
        title="Everyday Content - Données Personnelles et Charte Cookies"
        description="Everyday Content - Données Personnelles et Charte Cookies"
      />
      <Helmet>
        <script src="https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/css/v2/otnotice-core.css" integrity="sha384-DztdAPBWPRXSA/3eYEEUWrWCy7G5KFbe8fFjk5JAIxUYHKkDx6Qin1DkWx51bBrb" crossorigin="anonymous"></script>
      </Helmet>
      <Header
        data={headerData}
      />
      <div className="page_data_privacy page-wrapper">
        <div className="page-work inner-wrap">
          <div className="page-content inner-page">
            <div className="project-wrap">
              <a href="/" className="btn-back">
                <span>RETOUR</span>
              </a>
              {/* <!-- OneTrust Privacy Notice start -->
              <!--  Container in which the privacy notice will be rendered --> */}
              <div ref={policyTextRef} id="otnotice-c4bf13c9-3e34-4792-8ca5-3debf35b55da" className="otnotice"
                dangerouslySetInnerHTML={{ __html: content?.content }}></div>


            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulHeader {
      nodes {
        websiteTitle
        logo {
          title
          gatsbyImageData
        }
        menu {
          label
          externalLink
          link
        }
        socialIcons {
          label
          link
          externalLink
          icon {
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default Index;
